export function init() {
  const hamburgerBtn = $('.l-gnav__menu');
  const slideMenu = $('.l-gnav__list');

  menuToggle(hamburgerBtn, slideMenu);
  outsideLinkClick(hamburgerBtn, slideMenu);
}

/**
 * ハンバーガーアイコンとメニューの切り替え
 * @param {jQueryObject} hamburgerBtn 
 * @param {jQueryObject} slideMenu 
 */
function menuToggle(hamburgerBtn, slideMenu){
  hamburgerBtn.on('click', function(){
    $(this).add(slideMenu).toggleClass('on');
  });
}

/**
 * ページ内リンクをクリックしたときにonクラスを外す
 * @param {jQueryObject} hamburgerBtn 
 * @param {jQueryObject} slideMenu 
 */
function outsideLinkClick(hamburgerBtn, slideMenu){
  slideMenu.find('a').on('click', function(){
    hamburgerBtn.add(slideMenu).removeClass('on');
  });
}