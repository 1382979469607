export function init() {
  const changeDeviceSize = 768;

  $(window).on('load scroll resize', function() {
    const yScroll = window.pageYOffset;
    const fixedScroll = 525;
    const sidebar = $('.p-post-year__wrap');
    const sidebarHeight = $('.p-post-year__wrap').innerHeight();
    const pager = $('.wp-pagenavi')
    const activeClass = 'p-post-year__wrap--fixed';
    const stopClass = 'p-post-year__wrap--stop';

    const pageHeight = Math.max.apply(null, [document.body.clientHeight, document.body.scrollHeight, document.documentElement.scrollHeight, document.documentElement.clientHeight])
    const headerHeight = $('.l-header').innerHeight();
    const footerHeight = $('.l-footer').innerHeight();
    const pagerHeight = pager.outerHeight(true);

    if ($(window).innerWidth() > changeDeviceSize && pager.length) {
      // PCの処理、ページャーがあるページ
      const stopScroll = pageHeight - sidebarHeight - headerHeight - footerHeight - pagerHeight - 250;
      Fixedsidebar(yScroll, fixedScroll, stopScroll, sidebar, activeClass, stopClass);

    } else if ($(window).innerWidth() > changeDeviceSize) {
      // PCの処理、ページャーがないページ
      const stopScroll = pageHeight - sidebarHeight - headerHeight - footerHeight - 250;
      Fixedsidebar(yScroll, fixedScroll, stopScroll, sidebar, activeClass, stopClass);
      
    } else {
      // SPの処理
      sidebar.removeClass(activeClass);
    }
  });
}

/**
 * サイドバーのfixed制御
 * @param {jQueryObject} yScroll y軸のページのスクロール量
 * @param {Number} fixedScroll fixさせるときのスクロール量
 * @param {Number} fixedScroll fixを止めるときのスクロール量
 * @param {jQueryObject} sidebar サイドバー
 * @param {jQueryObject} activeClass fixedさせるクラス
 * @param {jQueryObject} stopClass fixedを止めるクラス
 * @param {Number} scrollValue アクティブになってるアイテムのスクロール値
 */

function Fixedsidebar(yScroll, fixedScroll, stopScroll, sidebar, activeClass, stopClass) {
  // サイドバーをfixさせる条件定義
  if (yScroll > fixedScroll) {
    sidebar.addClass(activeClass);
  } else {
    sidebar.removeClass(activeClass);
  }

  // コンテンツ下部でfixを止める
  if (yScroll > fixedScroll && yScroll > stopScroll) {
    sidebar.addClass(stopClass);
  } else {
    sidebar.removeClass(stopClass);
  }
}