export function init() {
  const header = $('.l-header--top');
  const changeDeviceSize = 1024;

  $(window).on('load scroll resize', function() {
    const headerHeight = $('.l-header').height();
    const windowHeight = window.innerHeight;
    const positionY = windowHeight - headerHeight;

    if ($(window).innerWidth() > changeDeviceSize) {
      // PCの処理
      fixedHeader(windowHeight, header, positionY);
    } else {
      // SPの処理
      $(header).css('top', 0);
      bgShow(header);
    }
  });
}

/**
 * 一定スクロールしたらヘッダーを追尾させる
 * @param {jQueryObject} windowHeight 
 * @param {jQueryObject} header 
 * @param {jQueryObject} positionY 
 */
function fixedHeader(windowHeight, header, positionY) {
  // ヘッダーの位置を固定
  $(header).css('top', positionY);

  if ($(window).scrollTop() > positionY) {
    $(header).addClass('fixed');
    $(header).css('top', 0);
  } else {
    $(header).removeClass('fixed');
    $(header).css('top', positionY);
  }
}

/**
 * 一定スクロールしたらヘッダーに背景を付ける
 * @param {jQueryObject} header 
 */
function bgShow(header) {
  if ($(window).scrollTop() > 50) {
    $(header).addClass('show');
  } else {
    $(header).removeClass('show');
  }
}