export function init() {
  const url = window.location.pathname;
  const activeGnavUrl = url.split('/')[1];
  const activePagenavUrl = url.split('/')[2];
  const gnavList = $('.l-gnav').find('a');
  const pageNavList = $('.l-subnav').find('a');
  // activateNavigation(activeGnavUrl, gnavList);
  // activatePageNavigation(activePagenavUrl, pageNavList);
}

/**
 * グローバルナビのhrefで1番目の「/」で区切られる部分が同じ場合アクティブにする
 * @param {jQueryObject} activeGnavUrl
 * @param {jQueryObject} gnavList
 */


function activateNavigation(activeGnavUrl, gnavList) {
  gnavList.each(function() {
    if ($(this).attr('href').split('/')[1] === activeGnavUrl) {
      $(this).addClass('current');
    } else {
      $(this).removeClass('current');
    }
  });
}


/**
 * ページ内ナビのhrefで2番目の「/」で区切られる部分が同じ場合アクティブにする
 * @param {jQueryObject} activePagenavUrl
 * @param {jQueryObject} pageNavList
 */
function activatePageNavigation(activePagenavUrl, pageNavList) {
  pageNavList.each(function() {
    if ($(this).attr('href').split('/')[2] === activePagenavUrl) {
      $(this).addClass('current');
    } else {
      $(this).removeClass('current');
    }
  });
}