export function init() {
  const innerMenu = $('.l-gnav__inner-list');
  const toggleBtnPC = $('.l-gnav__list li');
  const toggleBtnSP = $('.l-gnav__inner-trigger');
  const changeDeviceSize = 1024;

  $(window).on('load resize', function() {
    if($(window).innerWidth() > changeDeviceSize){
      // PCの処理
      pulldownMenuPC(innerMenu, toggleBtnPC);
    }
  });
  // SPの処理
  pulldownMenuSP(innerMenu, toggleBtnSP);
}

/**
* PC用プルダウンメニュー
**/
function pulldownMenuPC(innerMenu, toggleBtnPC){
  const activeClass = 'active';

  $(toggleBtnPC).on('mouseenter',function() {
    if($(this).find(innerMenu)) {
      $(this).find(innerMenu).children('li').addClass(activeClass);
    }
  }).on('mouseleave',function() {
    if($(this).find(innerMenu)) {
      $(this).find(innerMenu).children('li').removeClass(activeClass);
    }
  });
}

/**
* SP用プルダウンメニュー
**/
function pulldownMenuSP(innerMenu, toggleBtnSP) {
  const activeClass = 'show';

  $(toggleBtnSP).on('click', function(event){
    $(this).prev(innerMenu).toggleClass(activeClass);
    $(this).toggleClass(activeClass);
  });
}