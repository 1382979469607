import * as menuToggle from './common/menu_toggle.js';
import * as headerFixed from './common/header_fixed.js';
import * as navCurrent from './common/nav_current.js';
import * as sidebarFixed from './common/sidebar_fixed.js';
import * as navScroll from './common/nav_scroll.js';
import * as menuPulldown from './common/menu_pulldown.js';
import * as mvSlider from './common/mv_slider.js';

window.App = {
  init: function() {
    menuToggle.init(),
    headerFixed.init(),
    navCurrent.init(),
    sidebarFixed.init(),
    navScroll.init(),
    menuPulldown.init(),
    mvSlider.init()
  }
}

App.init();