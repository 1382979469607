/**
 * スマホ版ページ内ナビに関するDOM・スタイル・イベントの定義
 **/

export function init() {
  const pageNav = $('.l-subnav__list');
  const pageNavItem = $('.l-subnav__list li a');
  const myTarget = $('.l-subnav__list .current');
  const myIndex = pageNavItem.index(myTarget);

  const arrowArea = $('.p-subnav-arrow'); 
  const arrowNext = $('.p-subnav-arrow--next');
  const arrowPrev = $('.p-subnav-arrow--prev');

  const activeClass = 'l-subnav__list--left';
  const showClass = 'show';

  const changeDeviceSize = 767;

  $(window).on('load resize', function() {
    const itemWidth = pageNavItem.innerWidth();
    const scrollValue = itemWidth * myIndex;

    if ($(window).innerWidth() > changeDeviceSize) {
      // PC・タブレットの処理
      pageNav.removeClass(activeClass);
      arrowArea.removeClass(showClass);
    } else if (pageNav.length) {
      // SPの処理
      const pagaNavWidth = pageNav.get(0).scrollWidth;
      arrowArea.addClass(showClass);
      navSetting(pageNav, pagaNavWidth, activeClass, scrollValue);
      navScroll(pageNav, pagaNavWidth, arrowPrev, arrowNext, scrollValue)
      arrowClick(pageNav, arrowPrev, arrowNext, itemWidth);
    }
  })
}

/**
 * ページ内ナビがデバイス幅からはみ出るときに、スクロールナビを設置する
 * @param {jQueryObject} pageNav
 * @param {Number} pageNavWidth スクロールナビの横幅
 * @param {jQueryObject} activeClass トリガーになるクラス
 * @param {Number} scrollValue アクティブになってるアイテムのスクロール値
 */
function navSetting(pageNav, pagaNavWidth, activeClass, scrollValue) {
  if ($(window).innerWidth() < pagaNavWidth) {
    pageNav.addClass(activeClass);
    pageNav.scrollLeft(scrollValue);
  }
}

/**
 * ナビを横スクロールしたときに矢印ボタンの表示・非表示の切り替え
 * @param {jQueryObject} pageNav
 * @param {jQueryObject} arrowPrev
 * @param {jQueryObject} arrowNext
 * @param {Number} scrollValue アクティブになってるアイテムのスクロール値
 */
function navScroll(pageNav, pagaNavWidth, arrowPrev, arrowNext, scrollValue) {
  const diff = pagaNavWidth - $(window).innerWidth() - 5;

  if (scrollValue === 0) {
    arrowPrev.prop('disabled', true);
  }

  pageNav.on('scroll', function() {
    if ($(this).scrollLeft() === 0) {
      arrowPrev.prop('disabled', true);
    } else if($(this).scrollLeft() > diff) {
      arrowNext.prop('disabled', true);
    } else {
      arrowPrev.prop('disabled', false);
      arrowNext.prop('disabled', false);
    }
  })
}

/**
 * 矢印ボタンをクリックしたときの挙動
 * @param {jQueryObject} pageNav
 * @param {jQueryObject} arrowPrev
 * @param {jQueryObject} arrowNext
 * @param {Number} itemWidth ナビアイテムの横幅
 */
function arrowClick(pageNav, arrowPrev, arrowNext, itemWidth) {
  const speed = 300;

  arrowNext.on('click', function() {
    pageNav.animate({scrollLeft: itemWidth}, speed);
  });
  arrowPrev.on('click', function() {
    pageNav.animate({scrollLeft: -itemWidth}, speed);
  });
}