export function init() {
  mvSlider();
}

function mvSlider() {
  const slider = $('.p-mv__slider');

  $(slider).on('init', function(){
    $('.slick-slide[data-slick-index="0"]').addClass('moving');
  });

  $(slider).slick({
    arrow: false,
    dots: false,
    infinite: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    speed: 5000
  })
  .on('beforeChange', function(event, slick, currentSlide, nextSlide){
    var slide_num = $('.slick-slide').length;
    $('.slick-slide[data-slick-index="'+ ( currentSlide - 1 ) +'"]').removeClass('moving');
    $('.slick-slide[data-slick-index="'+nextSlide+'"]').addClass('moving');
    if( currentSlide == 0 ){
      $('.slick-slide[data-slick-index="'+ ( slide_num - 1 ) +'"]').removeClass('moving');
    }
  })
}